import {FormattedMetricData} from '../../../../store/state/metric-types/metric-types.types'
import {AnalysisType, MetricsSortType} from '../../../../store/state/metrics-filter-beta/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {AssetsAffected} from './metrics-summary'

export type PagedDataMetricsTableMap = Map<
    number,
    | MetricsResponseForAssetStatus[]
    | MetricsResponseForBenchmark[]
    | MetricsResponseForTarget[]
    | MetricsResponseForTrends[]
>
export type ApiMetricsTableMap = Map<AnalysisType, MetricsResponseData>

export interface MetricsResponseData {
    data: MetricsResponse
    totalNumberOfItems: number
    totalNumberOfPages: number
}
type commonProps = {
    location: GuidType
    locationName: string
    policy: GuidType
    policyName: string
    framework: string
    metric: MetricType
    formattedMetricForUI: FormattedMetricData
}
export interface MetricsResponseForAssetStatus extends commonProps {
    asset: GuidType
    assetName: string
    timeWithControlIssue: number
}
export interface MetricsResponseForBenchmark extends commonProps {
    benchmark: number
    performance: number
    assetsToFix: number
}
export interface MetricsResponseForTrends extends commonProps {
    window: number
    performance: number
}

export interface MetricsResponseForTarget extends commonProps {
    target: number
    performance: number
    assetsToFix: number
}

export type MetricsResponse =
    | MetricsResponseForAssetStatus[]
    | MetricsResponseForBenchmark[]
    | MetricsResponseForTrends[]
    | MetricsResponseForTarget[]

export interface MetricTableHeaderForUI {
    columnName: MetricsSortType
    columnText: string
    columnType: 'sortable' | 'fixed'
}
export enum MetricType {
    MALWARE_DETECTION = 'malwareDetection',
    SUPPORTED_OS = 'osSupported',
    PRIVILEGED_ACCOUNTS = 'priviligedAccounts',
    REMOVABLE_CONTROLS = 'removableControls',
    SECURITY_TOOLS = 'securityTools',
    ACCESS_CONTROLS = 'accessControls',
    PROTECTION = 'protection',
    MALWARE_DEFINITION = 'malwareDefinition',
    OS_SECURITY_PATCHES = 'osSecurityPatches',
    ASSET_ONLINE = 'assetOnline',
    MAINTENANCE = 'maintenance',
    USB_STORAGE = 'usbStorage',
    MOBILE_DEVICES = 'mobileDevices',
    HOTSPOT_MODE = 'hotspotMode',
    WIRELESS_CONNECTIVITY = 'wirelessConnection',
    ADMIN_LOGON = 'adminLogons',
    PORTABLE_SOFTWARE = 'portableSoftware',
    BEHAVIOUR = 'behaviour',
}

export const allAnalysisTypes = [
    AnalysisType.BENCHMARK,
    AnalysisType.TRENDS,
    AnalysisType.WORST_PERFORMING_VESSELS,
    AnalysisType.TARGET,
]
export interface MetricModalDetails {
    metricName: string
    metricValue: MetricType | string
    metricCategory: string
    locationName: string
    numberOfAssets: number
    assetsWithIssues: number
    scoreOfOkAssets: number
    appliedPolicyName: string
    vesselWithinTarget: string
    changeOverTrendPeriod: string
    targetForThisMetric: string
    assetsToFix: number
    assetsAffected: AssetsAffected[]
}
export const UNDEFINED_METRIC_MODAL_DETAILS: MetricModalDetails = {
    metricName: 'Unknown',
    metricValue: 'Unknown',
    metricCategory: 'Unknown',
    locationName: 'Unknown',
    numberOfAssets: 0,
    assetsWithIssues: 0,
    scoreOfOkAssets: 0,
    appliedPolicyName: 'Unknown',
    vesselWithinTarget: 'Unknown',
    changeOverTrendPeriod: 'Unknown',
    targetForThisMetric: 'Unknown',
    assetsToFix: 0,
    assetsAffected: [],
}
